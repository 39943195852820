import { useEffect, useState } from 'react';

import ListBags from './list';
import ListHeader from './listHeader';

const listView = ({
  route,
  match,
  data,
  columns,
  title,
  isLinked,
  isExternal,
  labelSearch,
  placeholderSearch,
}) => {
  const { order } = match.params;

  const [queryBag, setQueryBag] = useState('');
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [queryResultsBag, setQueryResultsBag] = useState(data.bags);

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      setQueryResultsBag((await route.loadDataFiltered(match, queryBag)).bags);
    }
  };

  useEffect(() => {
    (async () => {
      if (!queryResultsBag) {
        setQueryResultsBag((await route.loadData(match)).bags);
      }
    })();
  });

  useEffect(() => {
    (async () => setQueryResultsBag((await route.loadData(match)).bags))();
  }, [order]);

  useEffect(() => {
    (async () => {
      const queryBagResultsFiltered =
        queryResultsBag &&
        queryResultsBag.filter(
          (queryResultBag) =>
            queryResultBag.title?.toLowerCase().indexOf(queryBag.toLowerCase()) > -1
        );

      if (
        !isInitialRender &&
        (!queryBag || !queryResultsBag.length || !queryBagResultsFiltered.length)
      ) {
        setQueryResultsBag((await route.loadDataFiltered(match, queryBag)).bags);
      } else {
        setIsInitialRender(false);
        setQueryResultsBag(queryBagResultsFiltered);
      }
    })();
  }, [queryBag]);

  return (
    <>
      {title && <h2 className="text-lg font-bold py-2 text-heading">{title}</h2>}
      <label
        className="py-2 block text-sm text-primary font-medium text-gray-700 w-full"
        htmlFor="search"
      >
        {labelSearch}
        <input
          className="appearance-none border border-gray-300 rounded w-full p-2"
          name="search"
          placeholder={placeholderSearch}
          onChange={(event) => setQueryBag(event.target.value)}
          onKeyDown={handleKeyDown}
        />
      </label>
      <table className="w-full table-auto">
        <ListHeader columns={columns} match={match} />
        <ListBags bags={queryResultsBag} isLinked={isLinked} isExternal={isExternal} />
      </table>
    </>
  );
};

export default listView;
