import { useEffect } from 'react';
import { useAtom } from 'jotai';

import { atomFavorites, atomLocalFavorites } from '../atoms/favorites';

const handler = ({ sortBag, isInitial = false, bag = null }) => {
  const [, pigeonHoleFavorites] = useAtom(atomLocalFavorites);
  const [favorites] = useAtom(atomFavorites);

  useEffect(() => {
    if (isInitial) {
      pigeonHoleFavorites({
        bag: { id: bag._id, sort: bag.sort, title: bag.title },
        isRemoved: false,
      });
    } else {
      pigeonHoleFavorites();
    }
  }, []);

  const handleFavorite = () => {
    if (Array.isArray(favorites) && bag && bag.title) {
      pigeonHoleFavorites({
        bag: { id: bag._id, sort: bag.sort, title: bag.title },
        isRemoved: favorites.some((favorite) => favorite.sort === bag.sort),
      });
    }
  };

  return (
    <div className="h-full w-10 flex justify-end flex-grow flex-shrink-0">
      <div
        onClick={handleFavorite}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleFavorite();
          }
        }}
        role="button"
        tabIndex={-1}
      >
        {(favorites && favorites.some((favorite) => favorite.sort === parseInt(sortBag, 10)) && (
          <img
            className="w-10 min-w-10 max-w-10"
            alt="heart"
            src="/static/resources/icons/heart-filled.svg"
          />
        )) || (
          <img
            className="w-10 min-w-10 max-w-10"
            alt="heart"
            src="/static/resources/icons/heart.svg"
          />
        )}
      </div>
    </div>
  );
};

export default handler;
