import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import HandlerFavorite from '../../../favorites/components/handler';
import Photo from './photo';
import LinkBackward from '../../../../layout/components/linkBackward';

const createEntry = ({ match }) => {
  const { sortBag } = match.params;

  const imageElement = useRef(null);
  const history = useHistory();

  const [isReady, setIsReady] = useState(false);
  const [hasCapturedPhoto, setHasCapturedPhoto] = useState(false);
  const [isCapturingPhoto, setIsCapturingPhoto] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [messageError, setMessageError] = useState(undefined);
  const [isFirstEntry, setIsFirstEntry] = useState(false);

  const [name, setName] = useState('');
  const [wine, setWine] = useState('');
  const [vintage, setVintage] = useState('');
  const [location, setLocation] = useState({});
  const [photo, setPhoto] = useState('');
  const [bag, setBag] = useState(null);

  useEffect(() => {
    setIsReady(
      hasCapturedPhoto &&
        name &&
        name.length > 0 &&
        location &&
        location.lat &&
        location.lng &&
        photo &&
        photo.length
    );
  }, [hasCapturedPhoto, name, location, photo]);

  useEffect(() => {
    (async () => {
      const bagData = await (await fetch(`/api/bags/${sortBag}`)).json();
      setIsFirstEntry(!bagData.journeyEntries.length && !bagData.title);
      setBag(bagData);
    })();
  }, []);

  const geocode = ({ latitude, longitude }, address) => {
    const options = address ? { address } : { location: { lat: latitude, lng: longitude } };

    if (typeof google !== 'undefined') {
      new google.maps.Geocoder().geocode(options, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            setLocation({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
              coded: `${
                results[0].address_components.find((addressComponent) =>
                  addressComponent.types.includes('postal_code')
                ).long_name
              } ${
                results[0].address_components.find((addressComponent) =>
                  addressComponent.types.includes('locality')
                ).long_name
              }, ${
                results[0].address_components.find((addressComponent) =>
                  addressComponent.types.includes('country')
                ).long_name
              }`,
            });
          }
        }
      });
    } else {
      const containerScript = document.createElement('script');
      containerScript.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdh2f5FzXu2e4EtRwRguPRYkzUzods-Ks';
      containerScript.async = true;
      document.body.append(containerScript);

      containerScript.addEventListener('load', () => {
        geocode({ latitude, longitude }, address);
      });
    }

    setLocation({ ...location, lat: latitude, lng: longitude });
  };

  const handleLocationCapture = () => {
    if ('geolocation' in navigator) {
      const options = {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000,
      };

      setMessageError('');

      navigator.geolocation.getCurrentPosition(
        (position) => geocode(position.coords),
        () => {
          if (location.coded) {
            geocode({}, location.coded);
          } else {
            setMessageError(
              'Bitte geben Sie manuell eine Adresse ein oder verwenden Sie die Standortfreigabe des Browsers.'
            );
          }
        },
        options
      );
    }
  };

  const handleJourneyEntrySubmission = async () => {
    if (isFirstEntry && bag.title) {
      await fetch(`/api/bags/${sortBag}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...bag, title: bag.title, isPublic: bag.isPublic }),
      });
    }

    if (isReady) {
      const responseEntry = await fetch(`/api/bags/${sortBag}/entries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, wine, vintage, location, photo }),
      });

      if (responseEntry.status === 200) {
        const entry = await responseEntry.json();
        history.push(`/${sortBag}/${entry._id}`);
      } else if (responseEntry.status === 500) {
        const readReadableStream = async (reader, content) => {
          const { done, value } = await reader.read();

          if (done) {
            return content;
          }

          return readReadableStream(
            reader,
            content + value.reduce((acc, charCode) => acc + String.fromCharCode(charCode), '')
          );
        };

        const response = JSON.parse(await readReadableStream(responseEntry.body.getReader(), ''));

        setMessageError(response.message);
        setHasError(true);
      }
    }
  };

  return (
    <>
      {bag && (
        <>
          {isFirstEntry && (
            <div className="py-2">
              <h2 className="text-lg text-heading">Daten Wy-Sack</h2>
              <div className="flex">
                <label
                  htmlFor="title"
                  className="py-2 block text-sm font-medium text-gray-700 w-full"
                >
                  <div className="w-full flex justify-between">
                    Nickname/Titel
                    {!isReady && (!bag.title || bag.title.length <= 0) && (
                      <div className="text-right text-red-500 font-normal italic">
                        <span>* erforderlich</span>
                      </div>
                    )}
                  </div>
                  <input
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-100"
                    id="title"
                    placeholder="Wy-Sack-Nickname"
                    type="text"
                    onChange={(event) => setBag({ ...bag, title: event.target.value })}
                    value={bag.title || ''}
                  />
                </label>
              </div>
              <label
                htmlFor="public"
                className="flex py-2 block text-sm font-medium text-gray-700 col-span-1"
              >
                <div className="flex items-center h-5">
                  <input
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded disabled:opacity-50 disabled:bg-gray-100"
                    id="public"
                    type="checkbox"
                    defaultChecked={bag.isPublic}
                    onChange={(event) => {
                      setBag({ ...bag, isPublic: event.target.checked });
                    }}
                    value={bag.isPublic}
                  />
                </div>
                <div className="ml-3 text-sm">Öffentlich</div>
              </label>
            </div>
          )}
          <div className="h-full flex flex-row justify-between items-center">
            <h2 className="text-lg text-heading flex-shrink">Daten Reiseeintrag</h2>
            <HandlerFavorite sortBag={sortBag} isInitial={!isFirstEntry} bag={bag} />
          </div>
          <div className="py-2">
            <div className="grid grid-cols-3">
              <label
                htmlFor="name"
                className="py-2 block text-sm font-medium text-gray-700 col-span-3"
              >
                <div className="w-full flex justify-between">
                  Name
                  {!isReady && name.length <= 0 && (
                    <div className="text-right text-red-500 font-normal italic">
                      <span>* erforderlich</span>
                    </div>
                  )}
                </div>
                <input
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-100"
                  disabled={hasError}
                  id="name"
                  placeholder="Name der schenkenden Person(en)"
                  type="text"
                  onChange={(event) => setName(event.target.value)}
                  value={name}
                />
              </label>
              <div className="grid grid-cols-3 col-span-3 items-end">
                <label
                  htmlFor="wine"
                  className="py-2 block text-sm font-medium text-gray-700 col-span-2"
                >
                  Wein
                  <input
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-100"
                    disabled={hasError}
                    id="wine"
                    placeholder="Weinsorte"
                    type="text"
                    onChange={(event) => setWine(event.target.value)}
                    value={wine}
                  />
                </label>
                <label
                  htmlFor="vintage"
                  className="pl-2 py-2 block text-sm font-medium text-gray-700 col-span-1"
                >
                  Jahrgang
                  <input
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-100"
                    disabled={hasError}
                    id="vintage"
                    placeholder="Jahrgang"
                    type="number"
                    onChange={(event) => setVintage(event.target.value)}
                    value={vintage}
                  />
                </label>
              </div>
              <div className="grid grid-cols-3 col-span-3 items-end">
                <label
                  className="py-2 block text-sm font-medium text-gray-700 col-span-2"
                  htmlFor="location"
                >
                  <div className="w-full flex justify-between">
                    Standort
                    {!isReady && (!location || !location.lat || !location.lng) && (
                      <div className="text-right text-red-500 font-normal italic">
                        <span>* erforderlich</span>
                      </div>
                    )}
                  </div>
                  <input
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md col-span-2 disabled:opacity-50 disabled:bg-gray-100"
                    id="location"
                    type="text"
                    onChange={(event) => setLocation({ ...location, coded: event.target.value })}
                    value={`${location.coded || ''}`}
                  />
                </label>
                <button
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow col-span-1 my-2 ml-2 disabled:opacity-50 disabled:bg-gray-100"
                  disabled={hasError}
                  type="button"
                  onClick={handleLocationCapture}
                >
                  Auslesen
                </button>
              </div>
            </div>
            <img
              ref={imageElement}
              style={{ width: '100%', display: hasCapturedPhoto ? 'block' : 'none' }}
              alt="snapshot"
            />
            {!isCapturingPhoto && !isReady && !hasCapturedPhoto && photo.length <= 0 && (
              <div className="w-fit py-2">
                <div className="flex justify-between items-center">
                  <span className="py-1 text-sm font-medium text-gray-700">Foto</span>
                  <div className="text-sm text-right">
                    <span className="w-full text-red-500 font-normal italic">* erforderlich</span>
                  </div>
                </div>
                <button
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow disabled:opacity-50 disabled:bg-gray-100"
                  disabled={hasError}
                  type="button"
                  onClick={() => {
                    setIsCapturingPhoto(true);
                    setHasCapturedPhoto(false);
                  }}
                >
                  Kamera öffnen
                </button>
              </div>
            )}
            {hasCapturedPhoto && (
              <div className="w-full py-2 flex justify-between">
                <button
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow disabled:opacity-50 disabled:bg-gray-100"
                  disabled={hasError}
                  type="button"
                  onClick={() => {
                    setIsCapturingPhoto(true);
                    setHasCapturedPhoto(false);
                  }}
                >
                  Neues Foto
                </button>
                <button
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow disabled:opacity-50 disabled:bg-gray-100"
                  disabled={hasError}
                  type="button"
                  onClick={() => {
                    imageElement.current.src = '';
                    setHasCapturedPhoto(false);
                    setPhoto('');
                  }}
                >
                  Foto löschen
                </button>
              </div>
            )}
            {isCapturingPhoto && !hasCapturedPhoto && (
              <Photo
                onPhotoCapture={(dataUrlPhoto) => {
                  if (
                    dataUrlPhoto.split(',')[1].length > 0 &&
                    dataUrlPhoto.split(',')[1].trim() !== ''
                  ) {
                    imageElement.current.src = dataUrlPhoto;
                    setPhoto(dataUrlPhoto.split(',')[1]);

                    setHasCapturedPhoto(true);
                    setIsCapturingPhoto(false);
                  }
                }}
              />
            )}
          </div>
          <div className="py-4 border-t-2 flex flex-col items-center justify-center">
            {(hasError || messageError) && (
              <p className="mb-4 p-2 text-center text-sm rounded font-medium bg-red-100 animate-fade-in">
                {messageError}
              </p>
            )}
            <button
              className="w-2/3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow col-span-3 disabled:opacity-50 disabled:bg-gray-100"
              disabled={!isReady || hasError}
              type="button"
              onClick={handleJourneyEntrySubmission}
            >
              Reiseeintrag erstellen
            </button>
          </div>
        </>
      )}
      <LinkBackward to={`/${sortBag}`} className="px-2 py-4 text-secondary" />
    </>
  );
};

export default createEntry;
