import Helmet from 'react-helmet';
import { renderRoutes } from 'react-router-config';

const app = ({ route, data }) => {
  return (
    <div className="md:flex flex-col bg-white p-2 text-main">
      <Helmet>
        <title>Natao Wy-Reise-Sack</title>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta charset="utf-8" />
      </Helmet>
      {/* <img
        src="/static/resources/images/logo.png"
        className="h-24 object-contain object-left"
        alt="Natao Firmenlogo"
      /> */}
      {renderRoutes(route.routes, { data })}
    </div>
  );
};

export default app;
