import { useRef, useState } from 'react';

import PhotoCapture from '../../../../tooling/components/photoCapture';

const photo = ({ onPhotoCapture }) => {
  const [isReady, setIsReady] = useState(false);

  return (
    <PhotoCapture
      onIsReady={() => setIsReady(true)}
      render={(videoElement) => {
        const canvasElement = useRef(null);

        return (
          <div className="py-2">
            <button
              className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow disabled:opacity-50 disabled:bg-gray-100"
              disabled={!isReady}
              type="button"
              id="capture"
              onClick={() => {
                if (isReady || videoElement.current) {
                  canvasElement.current.width = videoElement.current.videoWidth;
                  canvasElement.current.height = videoElement.current.videoHeight;
                  canvasElement.current.getContext('2d').drawImage(videoElement.current, 0, 0);
                  onPhotoCapture(canvasElement.current.toDataURL('image/jpeg', 1.0));
                }
              }}
            >
              Foto aufnehmen
            </button>
            <canvas ref={canvasElement} style={{ display: 'none' }} />
          </div>
        );
      }}
    />
  );
};

export default photo;
