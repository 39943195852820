import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';

import { atomFavorites, atomLocalFavorites } from '../favorites/atoms/favorites';

const list = ({ bags, isLinked = true, isExternal = false }) => {
  const [, pigeonHoleFavorites] = useAtom(atomLocalFavorites);
  const [favorites] = useAtom(atomFavorites);

  useEffect(() => {
    pigeonHoleFavorites();
  }, []);

  return (
    <tbody>
      {bags &&
        bags.map((bag) => {
          return (
            <tr className="border-b last:border-b-0" key={bag.sort}>
              <td className="pl-6 py-1 flex justify-between">
                {(isLinked && (
                  <Link target={(isExternal && '_blank') || '_self'} to={`/${bag.sort}`}>
                    <span>{bag.title}</span>
                  </Link>
                )) || <span>{bag.title}</span>}
                {favorites && favorites.some((favorite) => favorite.sort === bag.sort) && (
                  <img
                    className="w-4 min-w-4 max-w-4"
                    alt="heart"
                    src="/static/resources/icons/heart-filled.svg"
                  />
                )}
              </td>
              <td className="pl-6 py-1">
                {(bag.distance / 1000).toFixed(2)}
                km
              </td>
              <td className="pl-6 py-1">{bag.journeyEntries}</td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default list;
