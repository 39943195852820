import { useEffect } from 'react';
import { useAtom } from 'jotai';

import { atomFavorites, atomLocalFavorites } from '../atoms/favorites';

import ListFavorites from '../components/list';
import LinkBackward from '../../../layout/components/linkBackward';

const listView = () => {
  const [, pigeonHoleFavorites] = useAtom(atomLocalFavorites);
  const [favorites] = useAtom(atomFavorites);

  useEffect(() => {
    pigeonHoleFavorites();
  }, []);

  return (
    <div className="py-2">
      <h2 className="text-lg text-heading">Meine favorisierten Wy-Säcke</h2>
      <table className="w-full table-auto">
        <ListFavorites favorites={favorites} />
      </table>
      <LinkBackward to="/" className="px-2 py-4 text-secondary" />
    </div>
  );
};

export default listView;
