import { useCallback, useEffect, useRef, useState } from 'react';

const gallery = ({ route, match, data }) => {
  const [photos, setPhotos] = useState(data.photos || []);
  const [indexPhotos, setIndexPhotos] = useState(0);
  const [widthContainerGallery, setWidthContainerGallery] = useState(0);
  const [widthWindow, setWidthWindow] = useState(0);

  const containerGallery = useRef(null);

  const handleClickForward = useCallback(
    () =>
      setIndexPhotos((indexPhotosCurrent) => {
        return indexPhotosCurrent < photos.length - 1 ? indexPhotosCurrent + 1 : 0;
      }),
    [photos]
  );

  const handleClickBackward = useCallback(
    () =>
      setIndexPhotos((indexPhotosCurrent) =>
        indexPhotosCurrent > 0 ? indexPhotosCurrent - 1 : photos.length - 1
      ),
    [photos]
  );

  useEffect(() => {
    (async () => {
      if (!photos.length) {
        setPhotos((await route.loadData(match)).photos);
      }
    })();
  }, []);

  useEffect(() => {
    const setWidth = () => {
      setWidthContainerGallery(containerGallery.current.offsetWidth);
      setWidthWindow(window.innerWidth);
    };

    window.addEventListener('resize', setWidth);
    setWidth();

    return () => window.removeEventListener('resize', setWidth);
  }, []);

  const currentSection = [
    ...photos.slice(
      indexPhotos,
      indexPhotos + (Math.floor(widthContainerGallery / Math.min(480, widthWindow)) || 1)
    ),
    ...photos.slice(
      0,
      indexPhotos + (Math.floor(widthContainerGallery / Math.min(480, widthWindow)) || 1) >
        photos.length
        ? indexPhotos +
            (Math.floor(widthContainerGallery / Math.min(480, widthWindow)) || 1) -
            photos.length
        : 0
    ),
  ];

  return (
    <div className="flex flex-row justify-center">
      <div
        className="flex items-center outline-none"
        onClick={handleClickBackward}
        onKeyDown={handleClickBackward}
        role="button"
        tabIndex={-1}
      >
        <img alt="Pfeil links" className="w-16" src="/static/resources/icons/chevron-left.svg" />
      </div>
      <div className="justify-center flex-grow grid grid-cols-gallery" ref={containerGallery}>
        {currentSection.map((photo) => (
          <img
            alt={`Reisebild ${indexPhotos}`}
            className="object-contain px-4"
            key={photo.idPhoto}
            src={
              photo.idPhoto
                ? `${process.env.HOST}/public/bags/${photo.sortBag}/${photo.idPhoto}.jpeg`
                : '/static/resources/images/app/placeholder.jpg'
            }
          />
        ))}
      </div>
      <div
        className="flex items-center outline-none"
        onClick={handleClickForward}
        onKeyDown={handleClickForward}
        role="button"
        tabIndex={-1}
      >
        <img alt="Pfeil rechts" className="w-16" src="/static/resources/icons/chevron-right.svg" />
      </div>
    </div>
  );
};

export default gallery;
