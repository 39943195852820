import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import routes from './routing/routes';

const data = window.__APP_DATA__ || {};

hydrateRoot(
  document.getElementById('root'),
  <BrowserRouter>{renderRoutes(routes, { data })}</BrowserRouter>
);

if (module.hot) {
  module.hot.accept();
}
