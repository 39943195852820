import { useEffect, useState } from 'react';

const viewEntry = ({ route, data, match }) => {
  const { idEntry, sortBag } = match.params;

  const [currentEntry, setCurrentEntry] = useState(
    data && data[sortBag] && data[sortBag].filter((entry) => entry._id === idEntry)[0]
  );

  useEffect(() => {
    (async () => {
      if (data[sortBag]) {
        setCurrentEntry(data[sortBag].filter((entry) => entry._id === idEntry)[0]);
      } else if (!currentEntry || currentEntry._id !== idEntry) {
        setCurrentEntry((await route.loadData(match))[match.params.sortBag]);
      }
    })();
  });

  return (
    <div className="border-b-2 border-primary-100">
      {currentEntry && (
        <>
          {currentEntry.idPhoto ? (
            <img
              alt="Reisebild"
              className="rounded-sm"
              src={`${process.env.HOST}/public/bags/${match.params.sortBag}/${currentEntry.idPhoto}.jpeg`}
            />
          ) : (
            <img
              alt="Platzhalter Reisebild"
              className="rounded-sm"
              src="/static/resources/images/app/placeholder.jpg"
            />
          )}
          <div className="w-full mt-2 flex justify-between">
            {currentEntry.wine && (
              <div className="grid grid-cols-heading-icon-left p-2">
                <img
                  className="row-span-2 max-h-6 pr-2"
                  alt="wine bottle"
                  src="/static/resources/icons/wine-bottle.svg"
                />
                <span className="font-bold">Wein</span>
                <span>{currentEntry.wine}</span>
              </div>
            )}
            {currentEntry.vintage && (
              <div className="grid grid-cols-heading-icon-right p-2">
                <span className="font-bold">Jahrgang</span>
                <img
                  className="row-span-2 max-h-6 pl-2"
                  alt="calendar"
                  src="/static/resources/icons/calendar.svg"
                />
                <span className="text-right">{currentEntry.vintage}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default viewEntry;
