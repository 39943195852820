import { useEffect, useRef } from 'react';

import QrScanner from 'qr-scanner';

const qrScan = ({ handleQrScan }) => {
  const videoElement = useRef(null);

  if (QrScanner.hasCamera()) {
    useEffect(() => {
      QrScanner.WORKER_PATH = '/static/scripts/qr-scanner/qr-scanner-worker.min.js';

      let qrScanner = new QrScanner(videoElement.current, (result) => {
        handleQrScan(result);

        qrScanner.stop();

        setTimeout(() => {
          if (qrScanner) {
            qrScanner.start();
          }
        }, 500);
      });

      setTimeout(() => {
        if (qrScanner) {
          qrScanner.start();
        }
      }, 500);

      return () => {
        qrScanner.destroy();
        qrScanner = null;
      };
    }, [handleQrScan]);
  }

  return <video style={{ width: '100%' }} playsInline ref={videoElement} />;
};

export default qrScan;
