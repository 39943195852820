import { Link } from 'react-router-dom';

const linkBackward = ({ to, className }) => {
  return (
    <Link to={to}>
      <div className={`flex ${className}`}>
        <img
          src="/static/resources/icons/arrow-narrow-left.svg"
          alt="navigation arrow left/back"
          className="w-4"
        />
        <span className="px-2">Zurück</span>
      </div>
    </Link>
  );
};

export default linkBackward;
