import { atom } from 'jotai';

const atomFavorites = atom([]);

const atomLocalFavorites = atom(null, (get, set, _arg) => {
  const { bag, isRemoved = false } = _arg || {};

  if (typeof window !== 'undefined') {
    // Get bags which were saved as favorites from the browser local storage API
    const { localStorage } = window;

    if (typeof _arg === 'object') {
      const favorites = [
        ...(JSON.parse(localStorage.getItem('favorites')) || []).filter(
          (favorite) => favorite.sort !== bag.sort
        ),
        ...(isRemoved ? [] : [bag]),
      ];

      localStorage.setItem('favorites', JSON.stringify(favorites));
      set(atomFavorites, favorites || bag);
    } else {
      set(atomFavorites, JSON.parse(localStorage.getItem('favorites')) || []);
    }
  }
});

export { atomFavorites, atomLocalFavorites };
