import { useEffect, useRef } from 'react';

const photoCapture = ({ render, onIsReady }) => {
  const videoElement = useRef(null);

  useEffect(() => {
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: { exact: 'environment' } } })
        .then((stream) => {
          const track = stream.getVideoTracks()[0];
          track
            .applyConstraints({
              aspectRatio: { ideal: 0.75 },
              advanced: [{ aspectRatio: 0.75 }],
            })
            .then(() => {
              if (videoElement.current !== null) {
                videoElement.current.srcObject = stream;
                onIsReady();
              }
            })
            .catch((ex) => {
              console.error(ex);
              console.error("couldn't satisfy camera restraints");
            });
        });
    }
  });

  return (
    <div>
      <video playsInline autoPlay ref={videoElement} style={{ width: '100%', height: '100%' }} />
      {render(videoElement)}
    </div>
  );
};

export default photoCapture;
