import { Link } from 'react-router-dom';

const list = ({ favorites, isExternal = false }) => {
  return (
    <tbody>
      {favorites &&
        favorites.map((favorite) => (
          <tr className="border-b last:border-b-0" key={favorite.id}>
            <td className="py-1 flex">
              <img
                className="w-4 min-w-4 max-w-4"
                alt="heart"
                src="/static/resources/icons/heart-filled.svg"
              />
              <Link
                className="ml-2"
                target={(isExternal && '_blank') || '_self'}
                to={`/${favorite.sort}`}
              >
                <span>{favorite.title}</span>
              </Link>
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default list;
