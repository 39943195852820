import React from 'react';
import 'isomorphic-unfetch';

import App from '../pages/app';
import BagsListView from '../bag/pages/listView';
import FavoritesListView from '../bag/favorites/pages/listView';
import Gallery from '../bag/components/gallery';
import GroupCreate from '../group/components/create';
import GroupsListView from '../group/pages/listView';
import HomePage from '../pages/home';
import JourneyEntriesList from '../bag/journey/components/entries/list';
import JourneyEntryCreate from '../bag/journey/components/entry/create';
import JourneyEntryView from '../bag/journey/components/entry/view';

const routes = [
  {
    component: App,
    routes: [
      {
        path: '/gallery/:chunk',
        exact: true,
        component: Gallery,
        loadData: async (match) => ({
          photos: await (
            await fetch(`${process.env.HOST}/api/entries/photos/random/${match.params.chunk}`)
          ).json(),
        }),
      },
      { path: '/favorites', exact: true, component: FavoritesListView },
      {
        path: '/leaderboard/:chunk/:by/:order',
        exact: true,
        component: function leaderboard({ data, match, route }) {
          return (
            <BagsListView data={data} match={match} route={route} bags={data.bags} isExternal />
          );
        },
        loadData: async (match) => ({
          bags: await (
            await fetch(
              `${process.env.HOST}/api/bags/top/${match.params.chunk}/${match.params.by}/${match.params.order}`
            )
          ).json(),
        }),
        loadDataFiltered: async (match, filter) => ({
          bags: await (await fetch(`${process.env.HOST}/api/bags/filter/${filter}`)).json(),
        }),
      },
      {
        path: '/groups/create/:sortBag?',
        exact: true,
        component: GroupCreate,
        routes: [{ path: '/groups/:titleGroup' }],
      },
      {
        path: '/groups/:titleGroup',
        component: GroupsListView,
        exact: true,
        loadData: async (match) => ({
          bags: await (
            await fetch(
              `${process.env.HOST}/api/groups/${match.params.titleGroup}/1000/distance/-1`
            )
          ).json(),
        }),
        loadDataFiltered: async (match, filter) => ({
          bags: await (
            await fetch(
              `${process.env.HOST}/api/groups/${match.params.titleGroup}/bags/filter/${filter}`
            )
          ).json(),
        }),
      },
      {
        path: '/groups/:titleGroup/edit',
        exact: true,
        component: GroupCreate,
        loadData: async (match) => ({
          bags: await (
            await fetch(
              `${process.env.HOST}/api/groups/${match.params.titleGroup}/1000/distance/-1/true`
            )
          ).json(),
        }),
      },
      {
        path: '/groups/:titleGroup/:chunk/:by/:order',
        component: GroupsListView,
        loadData: async (match) => ({
          bags: await (
            await fetch(
              `${process.env.HOST}/api/groups/${match.params.titleGroup}/${match.params.chunk}/${match.params.by}/${match.params.order}`
            )
          ).json(),
        }),
        loadDataFiltered: async (match, filter) => ({
          bags: await (
            await fetch(
              `${process.env.HOST}/api/groups/${match.params.titleGroup}/bags/filter/${filter}`
            )
          ).json(),
        }),
      },
      {
        path: '/:sortBag/create',
        exact: true,
        component: JourneyEntryCreate,
      },
      {
        path: '/:sortBag/:idEntry?',
        exact: true,
        component: JourneyEntriesList,
        loadData: async (match) => ({
          [match.params.sortBag]: await (
            await fetch(`${process.env.HOST}/api/bags/${match.params.sortBag}/entries`)
          ).json(),
        }),
        routes: [
          {
            path: '/:sortBag/:idEntry',
            exact: true,
            component: JourneyEntryView,
            loadData: async (match, data) => ({
              ...data,
              [match.params.sortBag]: await (
                await fetch(
                  `${process.env.HOST}/api/bags/${match.params.sortBag}/entries/${match.params.idEntry}`
                )
              ).json(),
            }),
          },
        ],
      },
      {
        path: '/',
        component: function homePage({ data, match, route }) {
          return (
            <HomePage data={data} match={match} route={route}>
              <Gallery data={data} match={match} route={route} />
            </HomePage>
          );
        },
        loadData: async () => ({
          photos: await (await fetch(`${process.env.HOST}/api/entries/photos/random/10`)).json(),
        }),
        routes: [
          {
            path: '/',
            exact: true,
            component: BagsListView,
            loadData: async () => ({
              bags: await (await fetch(`${process.env.HOST}/api/bags/top/20/distance/-1`)).json(),
            }),
            loadDataFiltered: async (match, filter) => ({
              bags: await (await fetch(`${process.env.HOST}/api/bags/filter/${filter}`)).json(),
            }),
          },
          {
            path: '/:chunk/:by/:order',
            exact: true,
            component: BagsListView,
            loadData: async (match) => ({
              bags: await (
                await fetch(
                  `${process.env.HOST}/api/bags/top/${match.params.chunk}/${match.params.by}/${match.params.order}`
                )
              ).json(),
            }),
            loadDataFiltered: async (match, filter) => ({
              bags: await (await fetch(`${process.env.HOST}/api/bags/filter/${filter}`)).json(),
            }),
          },
        ],
      },
    ],
  },
];

export default routes;
