import { Link } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

const home = ({ children, route, data }) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div className="w-full lg:max-w-5/12 flex flex-col justify-center items-center my-4 order-3 lg:order-1">
        <div className="sticky top-1/3">
          <div className="flex flex-col px-2">
            <h1 className="justify-center break-word font-bold text-center text-4xl uppercase tracking-tighter mb-2">
              Wy-Reise Schnappschüsse
            </h1>
            <div className="py-4">{children}</div>
          </div>
        </div>
      </div>
      <div className="w-full lg:max-w-1/3 flex flex-col order-1 lg:order-2">
        <img
          className="flex flex-shrink min-h-0 max-h-screen-1/2 object-contain"
          alt="Hand holding Bag"
          src="/static/resources/images/app/bag-logo.png"
        />
        <h1 className="flex flex-shrink-0 justify-center my-4 font-bold text-center text-4xl uppercase tracking-tighter">
          Wy-Reise-Sack
        </h1>
        <div className="flex flex-shrink-0 flex-col my-2 px-2 leading-snug tracking-tighter">
          <span>
            Ein schönes Geschenk? Ein guter Wein. Doch noch besser schmeckt der edle Tropfen aus dem
            nachhaltigen «Sack» aus Papier & Latex.
          </span>
          <span>Wiederverwendbar und waschbar geht der WY-REISE-SACK von Freund zu Freund.</span>
          <div className="flex my-2 items-center">
            <span className="font-bold">Tipp: </span>
            <a
              className="flex items-center text-primary"
              href="https://natao.ch/wy-sack"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-4 max-h-4 ml-4 mr-2"
                src="/static/resources/icons/arrow-narrow-right.svg"
                alt="navigation arrow right/forward"
              />
              <span>Wy-Reise-Sack direkt im Webshop kaufen</span>
            </a>
          </div>
        </div>
        <div className="flex flex-col justify-center my-4">
          <img
            className="flex flex-shrink min-h-0 object-contain"
            alt="Hand holding wine glass"
            src="/static/resources/images/app/journey.jpg"
          />
          <h1 className="flex flex-shrink-0 justify-center my-4 font-bold text-center text-4xl uppercase tracking-tighter">
            Die "Wy-Sack" Reisen
          </h1>
          <p className="mt-2 px-2 leading-snug tracking-tighter">
            Verfolge deinen WY-REISE-SACK online auf seiner Reise ins Unbekannte. Du findest ihn
            ganz einfach mit Hilfe des individuellen Namens, den du dem Sack zu Beginn gabst.
          </p>
        </div>
      </div>
      <div className="w-full lg:min-w-max lg:max-w-1/4 flex flex-col justify-center items-center my-4 order-2">
        <div className="flex flex-col sticky top-1/3 sm:mx-4">
          <div className="justify-center sm:p-4 sm:border sm:border-gray-300 sm:rounded-lg">
            <h1 className="flex flex-shrink-0 justify-center font-bold text-4xl uppercase tracking-tighter">
              Die Top 20
            </h1>
            <div className="px-2">{renderRoutes(route.routes, { data })}</div>
          </div>
          <Link className="mt-4 flex items-center text-primary" to="/favorites">
            <img
              className="w-4 max-h-4 ml-4 mr-2"
              src="/static/resources/icons/arrow-narrow-right.svg"
              alt="navigation arrow right/forward"
            />
            <span>Meine Wy-Säcke anschauen</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default home;
