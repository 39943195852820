import { Link } from 'react-router-dom';

const listHeader = ({ columns, match }) => {
  const { order = -1, by = 'distance' } = match.params;

  return (
    <thead>
      <tr>
        {columns &&
          columns
            .sort((columnA, columnB) => columnA.order - columnB.order)
            .map(
              (column) =>
                (column.to && (
                  <th className="px-2 text-left" key={column.title}>
                    <Link
                      className={`${
                        column.className || ''
                      } flex flex-row items-center font-bold mr-2 py-1`}
                      to={column.to}
                    >
                      <div className="flex flex-col flex-shrink-0 mr-1">
                        <img
                          alt="Pfeil nach oben"
                          className="w-3"
                          src={`/static/resources/icons/${
                            (column.to.includes(by) &&
                              parseInt(order, 2) === 1 &&
                              'chevron-up-thick.svg') ||
                            'chevron-up.svg'
                          }`}
                        />
                        <img
                          alt="Pfeil nach oben"
                          className="w-3"
                          src={`/static/resources/icons/${
                            (column.to.includes(by) &&
                              parseInt(order, 2) === -1 &&
                              'chevron-down-thick.svg') ||
                            'chevron-down.svg'
                          }`}
                        />
                      </div>
                      {column.title}
                    </Link>
                  </th>
                )) || (
                  <th key={column.title}>
                    <span className={`${column.className || ''} font-bold py-1`}>
                      {column.title}
                    </span>
                  </th>
                )
            )}
      </tr>
    </thead>
  );
};

export default listHeader;
