import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import HandlerFavorite from '../../../favorites/components/handler';
import LinkBackward from '../../../../layout/components/linkBackward';

const listEntries = ({ route, data, match }) => {
  const { sortBag, idEntry } = match.params;

  const containerMap = useRef(null);
  const [entries, setEntries] = useState(data[sortBag]);
  const [bag, setBag] = useState(null);

  const drawMap = () => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    const map = new google.maps.Map(containerMap.current, {
      zoom: 10,
      maxZoom: 14,
      center: { lat: 47.05, lng: 8.3 },
      disableDefaultUI: true,
      suppressMarkers: entries && entries.length > 1,
    });

    if (directionsRenderer && entries && entries.length > 1) {
      directionsRenderer.setMap(map);

      const entriesReversed = entries.slice().reverse();

      const origin = entriesReversed[0]?.location;
      const destination = entriesReversed[entriesReversed.length - 1]?.location;

      const waypoints = entriesReversed
        .slice(
          entriesReversed.length > 23 ? entriesReversed.length - 24 : 1,
          entriesReversed.length - 1
        )
        .reduce(
          (accumulator, current) => [
            ...accumulator,
            { location: `${current.location.lat}, ${current.location.lng}` },
          ],
          []
        );

      if (origin) {
        directionsService.route(
          {
            origin,
            destination,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status === 'OK') {
              directionsRenderer.setDirections(response);
            } else {
              console.error("couldn't render map");
              console.error(status);
              console.error(response);
            }
          }
        );
      }
    } else if (entries && entries.length === 1) {
      const locationEntry = { lat: entries[0].location.lat, lng: entries[0].location.lng };
      const marker = new google.maps.Marker({
        position: locationEntry,
      });

      marker.setMap(map);
      map.setCenter(marker.getPosition());
    }
  };

  useEffect(() => {
    if (typeof google !== 'undefined') {
      drawMap();
    } else if (entries) {
      const containerScript = document.createElement('script');
      containerScript.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdh2f5FzXu2e4EtRwRguPRYkzUzods-Ks';
      containerScript.async = true;
      document.body.append(containerScript);

      containerScript.addEventListener('load', () => {
        drawMap();
      });
    }
  }, [entries]);

  useEffect(() => {
    (async () => {
      if (!entries) {
        setEntries((await route.loadData(match))[sortBag]);
      }
      if (!bag) {
        setBag(await (await fetch(`/api/bags/${sortBag}`)).json());
      }
    })();
  });

  return (
    <>
      <div className="h-full flex flex-row justify-between items-center">
        <h2 className="text-lg font-bold p-2 text-heading flex-shrink">
          Reiseeinträge von {bag && bag.title}
        </h2>
        <HandlerFavorite sortBag={sortBag} bag={bag} />
      </div>
      {renderRoutes(route.routes, { data })}
      <div className="grid mt-2">
        <div className="grid grid-cols-4 auto-cols-max px-2 pt-2">
          <span className="col-span-3 font-bold">Gesamte Reisedistanz</span>
          <span className="font-bold">
            {entries &&
              (
                entries.reduce(
                  (accumulator, currentEntry) => accumulator + currentEntry.distance,
                  0
                ) / 1000
              ).toFixed(2)}
            km
          </span>
        </div>
        <div className="grid grid-cols-4 auto-cols-max px-2 pb-2">
          <span className="col-span-3 font-bold">Anzahl Schenkungen</span>
          <span className="font-bold">{entries && entries.length}</span>
        </div>
        <div className="grid grid-cols-4 auto-cols-max p-2">
          <span className="font-bold">Datum</span>
          <span className="col-span-2 font-bold">Person</span>
          <span className="font-bold">Distanz</span>
        </div>
        {entries &&
          entries.map((entry) => {
            const dateEntry = new Date(entry.createdAt);
            return (
              <Link to={`/${sortBag}/${entry._id}`} key={entry._id}>
                <div
                  className={`grid grid-cols-4 auto-cols-max p-2 ${
                    entry._id === idEntry && 'bg-gray-300 rounded'
                  }`}
                >
                  <span>
                    {dateEntry.getDate().toString().padStart(2, '0')}.
                    {(dateEntry.getMonth() + 1).toString().padStart(2, '0')}.
                    {dateEntry.getFullYear().toString().substring(2)}
                  </span>
                  <span className="col-span-2">{entry.name}</span>
                  <span>{(entry.distance && (entry.distance / 1000).toFixed(2)) || 0}km</span>
                </div>
              </Link>
            );
          })}
        <div className="h-64 my-2" ref={containerMap} />
      </div>
      <LinkBackward to="/" className="px-2 py-4 text-secondary" />
    </>
  );
};

export default listEntries;
