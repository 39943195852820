import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import QrScan from '../../tooling/components/qrScan';

const createGroup = ({ data, match, route }) => {
  const sortBag = Number.parseInt(match.params.sortBag, 10);

  const [bagsGroup, setBagsGroup] = useState(sortBag > 0 ? [sortBag] : []);
  const [titleGroup, setTitleGroup] = useState(match.params.titleGroup);
  const [isScanning, setIsScanning] = useState(false);

  const bagNumber = useRef(null);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (typeof route.loadData === 'function' && (!bagsGroup || !bagsGroup.length > 0)) {
        setBagsGroup((await route.loadData(match)).bags.map((bag) => bag.sort));
      }
    })();
  });

  const addBagToGroup = (bagGroup) => {
    const numberBagGroup = Number.parseInt(bagGroup, 10);
    if (
      numberBagGroup &&
      bagsGroup.indexOf(numberBagGroup) === -1 &&
      typeof numberBagGroup === 'number'
    ) {
      setBagsGroup([...bagsGroup, numberBagGroup]);
    }
  };

  const removeBagFromGroup = (bagGroup) =>
    setBagsGroup(bagsGroup.filter((bagGroupFiltered) => bagGroupFiltered !== bagGroup));

  const handleGroupSubmission = async () => {
    if (titleGroup && bagsGroup.length > 0) {
      const responseEntry = await fetch(`/api/groups`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ titleGroup, bagsGroup }),
      });

      if (responseEntry.status === 200) {
        const { group, bags } = await responseEntry.json();
        data.group = group;
        data.bags = bags;

        history.push(`/groups/${group.title}`);
      }
    }
  };

  return (
    <div className="py-2">
      <h2 className="text-lg text-heading my-2">Gruppendaten</h2>
      <label className="block text-sm font-medium text-gray-700 col-span-2" htmlFor="location">
        Gruppenname
        <input
          className="mr-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
          id="titleGroup"
          placeholder="Nickname / Gruppentitel"
          type="text"
          value={titleGroup}
          onChange={(event) => setTitleGroup(event.target.value)}
        />
      </label>
      <button
        className="bg-white hover:bg-gray-100 text-gray-800 w-full font-semibold py-2 px-4 border border-gray-400 rounded shadow col-span-1 my-4"
        type="button"
        onClick={() => setIsScanning(!isScanning)}
      >
        {(isScanning && 'Scan der QR-Codes beenden') || 'Wy-Säcke per QR-Scan erfassen'}
      </button>
      {isScanning && (
        <QrScan
          handleQrScan={(result) =>
            addBagToGroup(result.substring(result.lastIndexOf('/') + 1, result.length))
          }
        />
      )}
      {!isScanning && (
        <div className="w-full flex">
          <input
            className="mr-2 focus:ring-indigo-500 focus:border-indigo-500 block w-1/3 sm:text-sm border border-gray-300 rounded-md"
            placeholder="Wy-Sack Nr."
            type="text"
            ref={bagNumber}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                addBagToGroup(bagNumber.current.value);
                bagNumber.current.value = '';
              }
            }}
          />
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 w-2/3 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
            type="button"
            onClick={() => {
              addBagToGroup(bagNumber.current.value);
              bagNumber.current.value = '';
            }}
          >
            Hinzufügen
          </button>
        </div>
      )}
      {bagsGroup &&
        bagsGroup.map((bagGroup) => (
          <div
            className="w-max flex items-center mt-2"
            key={bagGroup}
            role="button"
            tabIndex={-1}
            onKeyDown={() => removeBagFromGroup(bagGroup)}
            onClick={() => removeBagFromGroup(bagGroup)}
          >
            {bagGroup}
            <div>
              <img
                className="h-6"
                src="/static/resources/icons/close.svg"
                alt="Wy-Sack entfernen"
              />
            </div>
          </div>
        ))}
      <div className="w-full my-4 py-4 border-t-2 flex justify-center">
        <button
          className="w-2/3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
          type="button"
          onClick={handleGroupSubmission}
        >
          Gruppe {`${route.path.match('/edit') ? 'aktualisieren' : 'erstellen'}`}
        </button>
      </div>
    </div>
  );
};

export default createGroup;
