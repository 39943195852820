import ListView from '../components/listView';

const listViewBags = ({ route, data, match, isLinked, isExternal }) => {
  const { order = -1 } = match.params;
  const regularExpression = /\/[^:]+\//i;
  const relativePath = match.path.match(regularExpression);

  return (
    <ListView
      route={route}
      match={match}
      data={data}
      isLinked={isLinked}
      isExternal={isExternal}
      columns={[
        {
          order: 1,
          title: 'Wy-Sack',
          to: `${(relativePath && relativePath[0]) || '/'}20/title/${order * -1 || -1}`,
        },
        {
          order: 2,
          title: 'Distanz',
          to: `${(relativePath && relativePath[0]) || '/'}20/distance/${order * -1 || -1}`,
        },
        {
          order: 3,
          title: 'Stopps',
          to: `${(relativePath && relativePath[0]) || '/'}20/journeyEntries/${order * -1 || -1}`,
        },
      ]}
      labelSearch="Nicht unter den Top 20?"
      placeholderSearch="Suche hier nach deinem Wy-Sack"
    />
  );
};

export default listViewBags;
