import ListView from '../../bag/components/listView';

const listViewGroup = ({ route, match, data }) => {
  const { order = -1, titleGroup } = match.params;

  return (
    <ListView
      route={route}
      match={match}
      data={data}
      columns={[
        {
          className: 'col-span-2',
          order: 1,
          title: 'Wy-Reise-Sack',
          to: `/groups/${titleGroup}/200/title/${order * -1 || -1}`,
        },
        {
          order: 2,
          title: 'Distanz',
          to: `/groups/${titleGroup}/200/distance/${order * -1 || -1}`,
        },
        {
          order: 3,
          title: 'Einträge',
          to: `/groups/${titleGroup}/200/journeyEntries/${order * -1 || -1}`,
        },
      ]}
      title={titleGroup}
    />
  );
};

export default listViewGroup;
